import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import Wrap from '../components/Wrap';
import Markdown from '../components/Markdown';

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      content: contentfulAboutPage {
        heroTitle1
        heroTitle2
        heroImage {
          fluid(maxWidth: 768, maxHeight: 512, cropFocus: CENTER, quality: 90) {
            ...GatsbyContentfulFluid
          }
        }
        text {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="About" />
      <Hero
        image={data.content.heroImage}
        title={[data.content.heroTitle1, data.content.heroTitle2]}
        titleIndent="first"
      />
      <Wrap>
        <MarkdownStyled
          dangerouslySetInnerHTML={{
            __html: data.content.text.childMarkdownRemark.html,
          }}
        />
      </Wrap>
    </Layout>
  );
};

const MarkdownStyled = styled(Markdown)`
  > :first-child {
    margin-top: 0;
  }
`;

export default AboutPage;
